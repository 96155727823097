import React from 'react';
import './AppTile.scss';

function AppTile({ url, name, description, index, enabled }) {
  return (
    <div
      className={`app-tile ${enabled ? '' : 'disabled'}`}
      onClick={() => enabled && window.open(url, '_blank')}
      role="link"
      tabIndex={index}
    >
      <div className={`app-tile-body ${enabled ? '' : 'disabled'}`}>
        <p>{name}</p>
      </div>
      <div className="app-tile-footer">
        <p>{description}</p>
      </div>
    </div>
  );
}

export default AppTile;

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import AppTile from '../AppTile/AppTile';
import './ExternalApps.scss';

function ExternalApps({ apps }) {
  const [userCognitoGroups, setUserCognitoGroups] = useState([]);

  useEffect(() => {
    async function getUserCognitoGroups() {
      const userCognitoGroups = await Auth.currentAuthenticatedUser().then((user) => user.signInUserSession.accessToken.payload['cognito:groups']);
      setUserCognitoGroups(userCognitoGroups);
    }
    getUserCognitoGroups();
  }, []);

  return (
    <div>
      <div className="app-tile-container-header">
        <span>Apps:</span>
      </div>
      <div className="app-tile-container">
        {apps.map((app, i) => (
          app.requiredCognitoGroups.some(requiredCognitoGroup => userCognitoGroups.includes(requiredCognitoGroup)) &&
            <AppTile
              url={app.url}
              name={app.name}
              description={app.description}
              index={i}
              enabled={app.enabled}
              key={app.name}
            />
        ))}
      </div>
    </div>
  );
}

export default ExternalApps;

import React from 'react';
import Amplify, { Logger } from 'aws-amplify';
import { SecuredVendorApp } from '@wfm-component-lib/auth';
import '@wfm-component-lib/auth/dist/index.css'

import ExternalApps from './components/ExternalApps/ExternalApps';
import appConfig from './AppConfig';
import './App.scss';

Amplify.Logger.LOG_LEVEL = appConfig.LOG_LEVEL || 'ERROR';
const logger = new Logger('App.js');

Amplify.configure(appConfig);
logger.debug('Amplify.configure', appConfig);

const APPS_DATA = appConfig.vmaApps;

function App() {

  return (
    <SecuredVendorApp
      appName={appConfig.appName}
      requiredCognitoGroups={appConfig.requiredCognitoGroups}
      groupToSkipVerification={appConfig.groupToSkipVerification}
      >
      <ExternalApps apps={APPS_DATA} />
    </SecuredVendorApp>

  );
}

export default App;

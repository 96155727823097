/* eslint-disable */
const domain = () => {
    const stage = process.env.REACT_APP_STAGE || ''
    let domain = ''
    if (stage.indexOf('dev') > -1)
        domain = '.vma-gdps-d.wfm.global'
    else if (stage.indexOf('tst') > -1)
        domain = '.vma-gdps-t.wfm.global'
    else if (stage === 'qa')
        domain = '.vma-gdps-q.wfm.global'
    else if (stage === 'prd')
        domain = '.vma-gdps.wfm.global'
    else
        domain = '.vma-gdps-d.wfm.global'
    return domain;
}
const appConfig = {
    LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL,
    appName: "Vendor Maintenance Application",
    requiredCognitoGroups: [
        'external-vendor',
        'p3-commodity-buyer',
        'p3-admin',
        'p3-accounting'
    ],
    groupToSkipVerification: `${process.env.REACT_APP_AWS_USER_POOL_ID}_${process.env.REACT_APP_OAUTH_AAD_PROVIDER_NAME}`,
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
    oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        scope: ["openid", "profile", "email", "aws.cognito.signin.user.admin"],
        redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
        responseType: "code",
        label: process.env.REACT_APP_OAUTH_COGNITO_BUTTON_LABEL,
        options: {
            provider: process.env.REACT_APP_OAUTH_AAD_PROVIDER_NAME
        }
    },
    Auth: (process.env.REACT_APP_STAGE === "local") ? undefined : {
        cookieStorage: {
            domain: domain(),
            path: '/',
            expires: 1,
            sameSite: "lax",
            secure: true
        }
    },
    vmaApps: [
        {
            name: 'VIRM',
            description: 'Vendor Item Relationship Maintenance',
            url: process.env.REACT_APP_VIRM_URL,
            enabled: true,
            requiredCognitoGroups: ['p3-commodity-buyer', 'p3-admin']
        },
        {
            name: 'OCA',
            description: 'Order Confirmations Application',
            url: process.env.REACT_APP_OCA_URL,
            enabled: true,
            requiredCognitoGroups: ['external-vendor']
        },
        {
            name: 'OCD',
            description: 'Order Confirmation Dashboard',
            url: process.env.REACT_APP_OCD_URL,
            enabled: true,
            requiredCognitoGroups: ['p3-commodity-buyer', 'p3-admin']
        },
        {
            name: 'SCA',
            description: 'Shipment Confirmation Application',
            url: process.env.REACT_APP_SCA_URL,
            enabled: true,
            requiredCognitoGroups: ['external-vendor']
        },
        {
            name: 'SCD',
            description: 'Shipment Confirmation Dashboard',
            url: process.env.REACT_APP_SCD_URL,
            enabled: true,
            requiredCognitoGroups: ['p3-accounting', 'p3-admin']
        }
    ]
};


export default appConfig;